<template>
  <div>
    <transition name="fade">
      <div v-if="familyId && fontdueLoaded">
        <button @click="openTrials" class="trail-btn fixed z-50">Trials</button>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    computed: {
      familyId () {
        return this.$store.state.fontdueId
      },
      fontdueLoaded () {
        return this.$store.state.fontdueLoaded
      }
    },
    methods: {
      openTrials () {
        if (!this.fontdueLoaded) return

        console.log('open trials', this.familyId)
        if (this.$store.state.modal) {
          this.$store.commit('CLOSE_MODAL')
          return
        }
        this.$store.commit('TRIALS_OPEN')
      }
    },
    mounted () {
      console.log('trials button..', this.fontdueLoaded)
    }
  }
</script>
