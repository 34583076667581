<template lang="pug">
.slice--split.relative(:style="{background: slice.primary.background_color}")
  section.-mb-px.grid-cols-1.lg-grid-cols-2.grid(:style="containerStyle", :class="containerClass")
    //- slice items
    .w-full.relative.overflow-hidden(v-for="(item, i) in slice.items", :class="{'lg-col-span-2': item.full_width, 'rounded-lg': rounded }", :style="itemStyle(item)", :key="i")
      figure.h-full.relative.flex.items-start.justify-center
        //- lottie
        lottie-animation(v-if="item.lottie_file", :data="item.lottie_file")
        //- svg graphic
        template(v-if="item.animation_frame_rate")
          svg-animation.relative.z-10.w-full(v-if="item.svg_graphic.url", :svgUrl="item.svg_graphic.url", :frameRate="item.animation_frame_rate", size="w-auto")
        template(v-else)
          img.w-full.block.relative.z-10(v-if="item.svg_graphic.url", :src="item.svg_graphic.url")
        //- bg
        slice-bg.absolute.overlay(v-if="item.height !== 'natural'" :body="item")
        img.block.w-full(v-if="item.height === 'natural' && item.bg_img.url" :src="item.bg_img.url", :alt="item.bg_img.alt")

        //- I'm sorry for this terrible hack, but I don't want to reprogram the existing video component to be block.
        div.w-full(v-if="item.height === 'natural' && item.bg_video_file.url")
          slice-bg.absolute.overlay(:body="item")
          //- Video below never plays, just used to be the correct proportions for the above video component.
          video.block.w-full(:src="item.bg_video_file.url" preload="false")

        prismic-link.absolute.inset-0(v-if="item.link && item.link.link_type !== 'Any'", :field="item.link")
</template>

<script>
import SliceBg from "@/components/SliceBg";
import svgAnimation from "@/components/SVGAnimation";
import LottieAnimation from "../../components/LottieAnimation.vue";

export default {
  name: "Slice--Margins",
  props: ["slice"],
  data() {
    return {
      margin: 100,
    };
  },
  computed: {
    containerStyle() {
      return {
        gap: `${this.margin}px`,
      };
    },
    marginsStyle() {
      return {
        minHeight: `calc(100vh - ${this.margin * 2}px)`,
      };
    },
    padded() {
      return !!this.slice?.primary?.margin;
    },
    rounded() {
      return !!this.slice?.primary?.corners;
    },
    containerClass() {
      if (this.padded) {
        return "mx-20px py-20px";
      }
      return "mx-20px lg-mx-100px py-20px lg-py-60px xl-mx-140px 2xl-py-140px";
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.$emit("ending");
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    itemStyle(item) {
      return {
        height:
          item.height !== "natural"
            ? `calc(100vh - ${this.margin * 2}px)`
            : undefined,
        backgroundColor: item.bg_color,
      };
    },
    onResize() {
      if (this.padded) {
        this.margin = 20;
        return;
      }
      if (window.innerWidth < 960) {
        this.margin = 20;
      } else if (window.innerWidth < 1440) {
        this.margin = 100;
      } else {
        this.margin = 140;
      }
    },
  },
  components: { SliceBg, svgAnimation, LottieAnimation },
};
</script>
