<template>
  <div v-click-outside="leave" class="modal leading-none rounded-lg m-auto w-full max-w-modal-content header-modal-height relative overflow-hidden">
    <div class="absolute top-0 right-0 p-40">
      <button @click="leave" class="text-white">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 104.4 104.39" class="fill-current w-20"><polygon points="104.4 9.58 94.82 0 52.2 42.63 9.58 0 0 9.58 42.62 52.2 .01 94.81 9.59 104.39 52.2 61.78 94.81 104.39 104.39 94.82 61.77 52.2 104.4 9.58"/></svg>
      </button>
    </div>
    <div class="max-h-full overflow-y-auto nobars p-40">
      <slot/>
    </div>
  </div>
</template>

<script>
  import ClickOutside from "vue-click-outside";

  export default {
    data () {
      return {
        fades: false
      }
    },
    computed: {
      modal () {
        return !!this.$store.state.modal
      }
    },
    methods: {
      leave () {
        if (!this.fades) return

        this.$store.commit('CLOSE_MODAL')
      }
    },
    watch: {
      modal (val) {
        if (val) {
          setTimeout(() => {
            if (!this.modal) return
            this.fades = true
          }, 400)
        } else {
          this.fades = false
        }
      }
    },
    directives: { ClickOutside }
  }
</script>

<style>
  .modal {
    font-size: 20px;
  }
  .max-w-modal-content {
    max-width: 572px;
  }
  .top-fade {
    height: 40px;
    background: linear-gradient(to bottom, rgb(27, 27, 27), transparent);
  }
  .bottom-fade {
    height: 40px;
    background: linear-gradient(to top, rgb(27, 27, 27), transparent);
  }
</style>
