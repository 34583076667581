<template>
  <div class="contents">
    <div class="mb-30 text-center">
      <p>{{ settings?.title || 'TRIALS' }}</p>
    </div>
    <div v-if="settings.description">
      <prismic-rich-text :field="settings.description" class="mb-30"></prismic-rich-text>
    </div>
    <fontdue-test-fonts-form :collection-slug="familyId" class="w-full"></fontdue-test-fonts-form>
  </div>
</template>

<script>
export default {
  computed: {
    familyId () {
      return this.$store.state.fontdueId
    },
    fontdueLoaded () {
      return this.$store.state.fontdueLoaded
    },
    settings () {
      if (!this.$store.state.main) return
      return {
        title: this.$store.state.main.test_fonts_title,
        description: this.$store.state.main.test_fonts_description
      }
    }
  },
  mounted () {
  }
}
</script>
