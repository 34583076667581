var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.theme)?_c('div',{staticClass:"relative z-10 flex items-center justify-center overflow-hidden leading-tight pointer-events-none header-row-height duration-300",class:{ 'header-modal-height': _vm.$store.state.modal }},[_c('div',{staticClass:"block w-full h-full overflow-hidden rounded-lg",style:({
      width: _vm.width ? _vm.width + 'px' : '100px',
      backdropFilter: _vm.theme.show_glass ? 'blur(96px)' : 'none',
      // backdropFilter: theme.show_glass ? 'invert(100%)' : 'none',
      background: !_vm.theme.show_glass
        ? _vm.theme.bar_bgcolor
        : `rgba(27,27,27,${_vm.modal ? 0.8 : 0.25})`,
      transition: `all ${_vm.speed}ms cubic-bezier(.16,1,.3,1)`,
      willChange: 'transform, width, height',
    })}),_c('div',{staticClass:"absolute left-0 flex items-center rounded-lg justify-items-center",style:({
      width: _vm.tablet.width + 'px',
      opacity: _vm.tablet.width > 0 ? _vm.tablet.opacity : 0,
      height: 'calc(100% - 2px)',
      backdropFilter: _vm.theme.show_glass ? 'blur(96px)' : 'none',
      background: 'rgba(255, 255, 255, 0.15)',
      transform: `translateX(${_vm.tablet.translateX}px)`,
      willChange: 'transform, width, height',
      transition: `opacity .6s cubic-bezier(.16,1,.3,1), transform ${_vm.speed}ms cubic-bezier(.16,1,.3,1)`,
    })})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }